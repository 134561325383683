import {EnvironmentInjector, Injectable} from '@angular/core';
import {filter, Observable, of, tap} from 'rxjs';
import {SiteRoute} from 'src/app/enums/core/site-route.enum';
import {REQUESTS_HASH} from 'src/app/helpers/core/misc.helper';
import {RadTemplateDto} from 'src/app/interfaces/backend/dto/rad-template-dto.interface';
import {RadiologyControllerService} from 'src/app/services/backend/radiology-controller.service';
import {displayError, displaySuccess} from 'src/app/utilities/toast.util';

@Injectable({providedIn: 'root'})
export class RadiologyTaskDescriptionsTemplatesFacade {
    public data: RadTemplateDto[] = [];
    public activeItem: RadTemplateDto | null = null;

    constructor(
        private radiologyControllerService: RadiologyControllerService,
        private injector: EnvironmentInjector
    ) {
    }

    public reset() {
        this.data = [];
        this.activeItem = null;
    }

    public loadData(id?: number, templateName?: string) {
        this.loadDataLogic(id, templateName).subscribe();
    }

    public loadDataLogic(id?: number, templateName?: string) {
        return this.getData(id, templateName).pipe(
            tap(data => this.data = data)
        );
    }

    public getData(id?: number, templateName?: string) {
        return id ?
            this.radiologyControllerService.getRadiologyTaskDescriptionTemplates(id, templateName) :
            this.radiologyControllerService.getAllRadiologyTaskDescriptionTemplates(templateName);
    }

    public loadItem(radTemplateId: number, callback?: () => void) {
        this.getItem(radTemplateId).subscribe((data) => {
            this.activeItem = data;
            callback?.();
        });
    }

    public getItem(radTemplateId: number) {
        return this.radiologyControllerService.getRadiologyTaskDescriptionTemplate(radTemplateId);
    }

    public create(radTemplateDto: RadTemplateDto, callback?: () => void) {
        this.radiologyControllerService.createRadiologyTaskDescriptionTemplate(radTemplateDto).subscribe((data) => {
            this.activeItem = data;
            displaySuccess(this.injector);
            callback?.();
        });
    }

    public update(radTemplateDto: RadTemplateDto, callback: () => void) {
        const fn = (id: number) => this.radiologyControllerService.updateRadiologyTaskDescriptionTemplate(id, radTemplateDto)
                                       .subscribe((data) => {
                                           this.activeItem = data;
                                           displaySuccess(this.injector);
                                           callback();
                                       });

        this.getItemId(fn);
    }

    public delete(radTemplateId: number, callback?: () => void) {
        this.radiologyControllerService.deleteRadiologyTaskDescriptionTemplate(radTemplateId).subscribe(() => {
            displaySuccess(this.injector);
            callback?.();
        });
    }

    public getAvailableProceduresForRadiologyTaskDescriptionTemplate(additionalProcedureId?: number) {
        return this.radiologyControllerService.getAvailableProceduresForDescriptionTemplate(additionalProcedureId);
    }

    public loadRadiologyTaskDescriptionsTemplatesForRadiologyTask(radTaskId: number) {
        this.loadRadiologyTaskDescriptionsTemplatesForRadiologyTaskLogic(radTaskId).subscribe();
    }

    public loadRadiologyTaskDescriptionsTemplatesForRadiologyTaskLogic(radTaskId: number) {
        return this.radiologyControllerService.getDescriptionsTemplatesForRadiologyTask(radTaskId).pipe(
            tap(data => this.data = data)
        );
    }

    public getItemId<T>(fn: (id: number) => T) {
        const id = this.activeItem?.id;
        if (!id) {
            displayError(this.injector);
            return;
        }

        return fn(id);
    }

    public handleSubject(param?: string, requestsHash?: string) {
        if (!param) return of(null);

        const userId = Number.parseInt(param);
        const request: Observable<RadTemplateDto | null> = param === SiteRoute.CREATE ? of(null) : this.getItem(userId);
        return request.pipe(
            filter(() => requestsHash === REQUESTS_HASH),
            tap(data => this.activeItem = data)
        );
    }
}